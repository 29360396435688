<template>
  <div class="ps-page--my-account">
    <div class="ps-my-account">
      <div class="ps-container">
        <form class="ps-form--account ps-tab-root">
          <ul class="ps-tab-list">
            <li class="active"><a href="#sign-in">Login</a></li>
            <li><a href="#register">Register</a></li>
          </ul>
          <div class="ps-tabs">
            <div class="ps-tab active" id="sign-in">
              <Login></Login>
              <AuthFooter :section="'login'"></AuthFooter>
            </div>
            <div class="ps-tab" id="register">
              <Register></Register>
              <AuthFooter :section="'register'"></AuthFooter>
            </div>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import global from "@/mixins/global.js";
import Register from "./Auth/Register";
import Login from "./Auth/Login";
import AuthFooter from "./Auth/AuthFooter";
export default {
  components: {AuthFooter, Login, Register},
  data() {
    return {
      user: {
        email: "",
        name: "",
        verification_code: "",
        password: "",
        confirm_password: "",
      },
      error: {
        email: [],
        password: [],
        message: "",
      },
      loading: false,
      verify: false
    };
  },
  mixins: [global]
};
</script>

<style>
</style>