<template>
  <div class="ps-tab active" id="register">
    <div class="ps-form__content">
      <h5>Register An Account</h5>
      <div v-if="errors.message" class="alert alert-danger" role="alert">
        {{ errors.message }}
      </div>
      <div class="form-group">
        <input
          class="form-control"
          type="text"
          placeholder="Full Name"
          v-model="form.name"
        />
        <small v-if="errors.name" class="phone_error text-danger">
          {{ errors.name[0] }}
        </small>
      </div>
      <div class="form-group">
        <div class="row">
          <!--                  TODO - Get the countries from backend?-->
          <div class="col-6">
            <select
              v-model="form.country"
              class="form-control"
              @change="selectedCountry($event)"
            >
              <option :value="country.id" v-for="country in countries">{{
                country.name + " (+" + country.zip + ") "
              }}</option>
            </select>
            <small v-if="errors.country" class="country_error text-danger">
              {{ errors.country[0] }}
            </small>
          </div>
          <div class="col-6">
            <input
              type="number"
              class="form-control"
              v-model="form.phone_number"
              aria-label="Text input with dropdown button"
              placeholder="0777 123 123"
            />
            <small
              v-if="errors.hasTypedZipInPhone"
              class="phone_error text-danger"
            >
              {{ errors.hasTypedZipInPhoneError }}
            </small>
          </div>
        </div>
        <small v-if="errors.phone_number" class="phone_number_error text-danger">
          {{ errors.phone_number[0] }}
        </small>
      </div>

      <div class="form-group">
        <input
          class="form-control"
          type="email"
          placeholder="Email"
          v-model="form.email"
        />
        <small v-if="errors.email" class="phone_error text-danger">
          {{ errors.email[0] }}
        </small>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          type="password"
          placeholder="Password"
          v-model="form.password"
        />
        <small v-if="errors.password" class="password_error text-danger">
          {{ errors.password[0] }}
        </small>
      </div>
      <div class="form-group">
        <input
          class="form-control"
          type="password"
          v-model="form.confirm_password"
          placeholder="Confirm Password"
        />
        <small v-if="errors.confirm_password" class="confirm_password_error text-danger">
          {{ errors.confirm_password[0] }}
        </small>
      </div>
      <div class="form-group submit">
        <button
          class="ps-btn ps-btn--fullwidth register"
          :disabled="isLoading"
          @click.prevent="onSubmit()"
        >
          {{ isLoading ? "Processing..." : "Register" }}
          <i v-if="!isLoading" class="icon icon-right-arrow ml-1"></i>
          <span
            v-if="isLoading"
            class="spinner-border text-light"
            role="status"
          ></span>
        </button>
      </div>
    </div>
    <AuthFooter :section="'register'"></AuthFooter>
  </div>
</template>

<script>
import axios from "axios";
import AuthFooter from "./AuthFooter";

export default {
  name: "Register",
  components: { AuthFooter },
  data() {
    return {
      form: {
        email: "",
        name: "",
        phone_number: "263",
        country: 234,
        dialing_code: 263,
        password: "",
        confirm_password: "",
      },
      errors: {
        email: [],
        password: [],
        phone_number: [],
        country: [],
        name: [],
        message: "",
        selectedCountry: "",
        hasTypedZipInPhone: false,
        hasTypedZipInPhoneError: "",
      },
      countries: [],
      isLoading: false,
    };
  },
  watch: {
    "form.phone_number"(newVal) {
      this.errors.hasTypedZipInPhone = false;
      this.errors.hasTypedZipInPhoneError = "";
      var lengthOfZip = ("" + this.form.dialing_code).length;
      var startOfPhone = ("" + newVal).slice(0, lengthOfZip);

      // var format = /\+/i;
      // alert("ans " + JSON.stringify(format.test(startOfPhone)));
      if (startOfPhone.includes("" + this.form.dialing_code)) {
        // this.errors.hasTypedZipInPhone = true;
        // this.errors.hasTypedZipInPhoneError = "Please remove country code";
      }
    },
  },
  mounted() {
    this.getCountries();
  },
  methods: {
    selectedCountry($event) {
      let countryId = event.target.value;
      this.countries.forEach((country) => {
        if (country.id == countryId) {
          this.form.dialing_code = country.zip;
          this.form.phone_number = country.zip;
        }
      });
    },
    getCountries() {
      axios.get(`countries`).then((response) => {
        this.countries = response.data.filter(
          (country) => country.zip && country.code
        );
        //console.log(JSON.stringify(this.countries));
      });
    },

    onSubmit() {
      if (this.errors.hasTypedZipInPhone) {
        this.$toasted.error("Please enter valid data");
        return;
      }
      this.isLoading = true;
      for (var i = 0; i < this.countries.length; i++) {
        if (this.countries[i].id == this.form.country) {
          this.form.dialing_code = this.countries[i].zip;
          //alert(this.countries[i].zip + " Found");
          break;
        }
      }
      // this.form.phone_number = this.form.dialing_code + this.form.phone_number.replace(/^0+/, '');
      this.form.phone_number = this.form.phone_number.replace(/^0+/, '');
      // return console.log(this.form.phone_number);
      axios
        .post("register", this.form)
        .then((response) => {
          if (response.status === 200 || response.status === 201) {
            this.$toasted.show("You have successfully registered");
            this.$store.dispatch("auth/updateProfile", response.data.user);
            this.$store.dispatch("auth/updateToken", response.data.token);
            setTimeout(() => this.$router.push({ name: "home" }), 2000);
          }
          this.isLoading = false;
        })
        .catch((error) => {
          this.$toasted.error("Account could not be created.");
          if (error) {
            if (error.response && error.response.status === 422) {
              if (error.response.data.message) {
                this.errors.message = error.response.data.message;
              } else {
                this.errors.message =
                  "Account could not be created. Please enter all valid required fields.";
              }
              this.errors = error.response.data.errors;
            } else if (error.response && error.response.status === 401) {
              this.errors.message = error.response.data.message;
            } else if (error.backendErrors) {
              this.errors.message = error.backendErrors[0];
            }
          } else {
            this.errors.message = "Unexpected error occurred.";
          }
          this.isError = true;
          this.isLoading = false;
        });
    },
  },
};
</script>

<style scoped></style>
